import {mapActions} from "vuex";
import {bus} from '@/helpers/bus';

import Utils from '@/helpers/utils';
import Dh from '@/helpers/date.helper';

import CustomFilter from "@/components/Filters/Custom/index";

import FtpService from '@/services/ftp.service';

const ftpService = new FtpService();

export default {
  name: 'ftpFilesList',

  data() {
    return {
      ftpFiles: [],
      ftpFile: null,

      ftpAccounts: [],

      deleteModal: false,
      isLoading: false,

      perPage: 25,
      totalRows: 0,
      currentPage: 1,

      feedTypes: [
        {key: 'INVENTORY', text: this.$t('inventory')},
        {key: 'DEAL', text: this.$t('deal')},
        {key: 'LEAD', text: this.$t('lead')},
      ],

      // Filters
      searchTerm: '',
      ftpAccountId: '',
    }
  },

  components: {
    CustomFilter
  },

  computed: {
    ftpAccountsFilter() {
      return this.ftpAccounts.map(el => ({value: el._id, text: el.Username}));
    }
  },

  async mounted() {
    await Promise.all([this.list(), this.listFtpAccounts()]);
  },

  methods: {
    ...mapActions(['setUploadImportFileData']),

    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
        ftpAccount: this.ftpAccountId,
      }

      const res = await ftpService.listFiles(this.perPage, skip, filters);
      if (res && !res.error) {
        this.ftpFiles = res.data.ftpFiles;
        this.totalRows = res.data.count;
      }
    },

    async remove() {
      this.isLoading = true;

      const ftpFileIdx = this.ftpFiles.findIndex(el => el._id === this.ftpFile._id);

      const res = await ftpService.deleteFile(this.ftpFile._id);
      if (res && !res.error) {
        this.closeModal();

        if (ftpFileIdx > -1) {
          this.ftpFiles.splice(ftpFileIdx, 1);
        }
      }

      this.isLoading = false;
    },

    async onSelectFileType(event, ftpFile) {
      this.ftpFile = ftpFile;
      await this.edit();
    },

    async edit() {
      this.isLoading = true;

      const ftpFileIdx = this.ftpFiles.findIndex(el => el._id === this.ftpFile?._id);

      const res = await ftpService.editFile(this.ftpFile);
      if (res && !res.error) {
        if (ftpFileIdx > -1) {
          this.ftpFile[ftpFileIdx] = {
            ...this.ftpFile,
            updatedAt: new Date(),
          };
        }

        this.closeModal();
      }

      this.isLoading = false;
    },

    async forwardToImportFile(ftpFile) {
      const {FileFeedType} = ftpFile;

      const forwardLinks = {
        INVENTORY: {
          name: 'ImportCarInventoryMatch',
          params: {
            feedFileId: ftpFile._id
          }
        },
        DEAL: {
          name: 'ImportDealMatch',
          params: {
            feedFileId: ftpFile._id
          }
        },
        LEAD: {
          name: 'ImportLeadMatch',
          params: {
            feedFileId: ftpFile._id
          }
        },
      }

      await this.setUploadImportFileData(null);
      await this.$router.push(forwardLinks[FileFeedType]);
    },

    async listFtpAccounts() {
      const res = await ftpService.listAccounts(100, 0);
      if (res && !res.error) {
        this.ftpAccounts = res.data.ftpAccounts;
      }
    },

    async onSelectFtpAccount(account) {
      this.ftpAccountId = account?.value || '';
      await this.list();
    },

    openModal(modal, ftpFile) {
      this[modal] = true;

      if (ftpFile) {
        const modals = {
          deleteModal: {...ftpFile},
        }

        this.ftpFile = modals[modal];
      }
    },

    closeModal() {
      this.deleteModal = false;
      this.isLoading = false;
      this.ftpFile = null;
    },

    async downloadFile(ftpFile) {
      const res = await ftpService.downloadFile(ftpFile._id);

      if (res && !res.error) {
        const downloaded = Utils.ForceFileDownload(res.data, ftpFile.FileName);

        if (downloaded) {
          this.$notify({type: 'success', message: this.$t('file_downloaded_successfully')});
        } else {
          this.$notify({type: 'error', message: this.$t('file_download_error')});
        }

      } else {
        this.$notify({type: 'error', message: this.$t('file_download_error')});
      }

      this.$hideModals();
    },

    tooltipDate(value) {
      if (value) return Dh.customFormat(value, 'lll');
      return 'N/A';
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    highlight(text) {
      return Utils.HighlightText(text, this.searchTerm);
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },
  },

  watch: {
    async perPage() {
      await this.list();
    },

    async searchTerm() {
      await this.list();
    },
  },

  filters: {
    formatDate(value) {
      if (value) return Dh.formatDate(value);
      return 'N/A';
    },

    fileSize(fileStats) {
      const {size} = fileStats; // Bytes
      const sizeKb = size / 1024;
      const sizeMB = sizeKb / 1024;

      if (sizeMB >= 1) {
        return sizeMB.toFixed(2) + ' MB';
      } else {
        return sizeKb.toFixed(2) + ' KB';
      }
    }
  },
}