import IService from './iservice';
import config from "@/config";
import store from "../store/store";

const ftp_web_server = config[config.stage].ftp_web_server,
    ftp_account_api = `${ftp_web_server}/api/ftp-account`,
    ftp_file_api = `${ftp_web_server}/api/ftp-file`;

class FtpService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async createAccount(ftpAccount) {
    try {
      const response = await this.http.post(ftp_account_api, ftpAccount);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getAccount(id) {
    try {
      const response = await this.http.get(`${ftp_account_api}/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async deleteAccount(id) {
    try {
      const response = await this.http.delete(`${ftp_account_api}/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listAccounts(limit, skip, filters = {}) {
    try {
      const query = {...filters};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;

      this.addLoading('list_ftp_accounts');
      const response = await this.http.get(ftp_account_api, query);
      this.delLoading('list_ftp_accounts');
      return response.data;
    } catch (e) {
      this.delLoading('list_ftp_accounts');
      return null;
    }
  }

  async editAccount(ftpAccount) {
    try {
      const response = await this.http.put(ftp_account_api, ftpAccount);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async resetAccountPassword(username, newPassword, newPasswordConfirm) {
    try {
      const response = await this.http.put(`${ftp_account_api}/reset-password`, {
        username, newPassword, newPasswordConfirm
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }


  async listFiles(limit, skip, filters = {}) {
    try {
      const query = {...filters};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;

      this.addLoading('list_ftp_files');
      const response = await this.http.get(`${ftp_file_api}/`, query);
      this.delLoading('list_ftp_files');
      return response.data;
    } catch (e) {
      this.delLoading('list_ftp_files');
      return null;
    }
  }

  async editFile(ftpFile) {
    try {
      const response = await this.http.put(ftp_file_api, ftpFile);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async downloadFile(id) {
    try {
      const response = await this.http.download(`${ftp_file_api}/download/${id}`);
      return response;
    } catch (e) {
      return null;
    }
  }

  async deleteFile(id) {
    try {
      const response = await this.http.delete(`${ftp_file_api}/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default FtpService;